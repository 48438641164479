import { ReactElement, useState } from 'react';
import { DriException, DriSchedule } from './DRI_ScheduleModal';
import { Flex } from 'reflexbox';
import { Button } from 'components';
import { useStateVar } from 'helpers/useStateVar';
import { ExceptionSchedCard, LimitExceedTooltip, SchedCard } from './DRI_Shedule';
import { useTranslation } from 'react-i18next';
import { getUserProfile } from '~/helpers/userProfile';

const LIMIT_EXCEPTIONS = 6;
const LIMIT_SCHEDULES = 6;

interface DriScheduleListProps {
  driCfg: { application: string, protocol: string};
  schedules: DriSchedule[];
  exceptions: DriException[];
  onAddEdit: (sched?: DriSchedule | DriException) => void;
  onDelete: (sched: DriSchedule | DriException) => void;
  onChangeShowException: (value: boolean) => void;
  size?: 'small';
  hideAddButton?: boolean;
  clientId?: number;
}

export const DriScheduleList = ({
  exceptions,
  schedules,
  driCfg,
  onDelete,
  onAddEdit,
  size,
  onChangeShowException,
  hideAddButton,
  clientId,
}: DriScheduleListProps): ReactElement => {
  const { t } = useTranslation();
  const [profile] = useState(getUserProfile);

  const [state, , setState] = useStateVar({
    showExceptions: false,
  });

  function verifyProfileCanManageProgramming() {
    return !!(profile.manageAllClients || profile.adminClientProg?.CLIENT_MANAGE.some((item) => item === clientId));
  }

  const limitExceeded = state.showExceptions ? exceptions.length >= LIMIT_EXCEPTIONS : schedules.length >= LIMIT_SCHEDULES;

  return (
    <Flex
      flexWrap="nowrap"
      flexDirection="column"
      alignItems="left"
      width={size === 'small' ? '791px' : undefined}
      style={{
        borderRadius: '5px',
        borderBottom: size === 'small' ? '1px solid lightgrey' : undefined,
      }}
    >
      <Flex
        flexWrap="nowrap"
        flexDirection="column"
        alignItems="left"
        width="768px"
        style={{
          borderRadius: '10px',
          width: '100%',
        }}
      >
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '150px 6px 150px auto',
            height: '5px',
            marginTop: '24px',
          }}
        >
          <span
            style={{
              borderTop: '1px solid lightgrey',
              borderRight: '1px solid lightgrey',
              borderLeft: '1px solid lightgrey',
              borderRadius: '6px 6px 0 0',
              backgroundColor: state.showExceptions
                ? '#f4f4f4'
                : 'transparent',
            }}
          />
          <span />
          <span
            style={{
              border: '1px solid lightgrey',
              borderBottom: 'none',
              borderRadius: '6px 6px 0 0',
              backgroundColor: state.showExceptions
                ? 'transparent'
                : '#f4f4f4',
            }}
          />
          <span />
        </div>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '150px 6px 150px auto',
          }}
        >
          <span
            style={{
              borderRight: '1px solid lightgrey',
              borderLeft: '1px solid lightgrey',
              textAlign: 'center',
              fontSize: '90%',
              borderBottom: state.showExceptions
                ? '1px solid lightgrey'
                : 'none',
              backgroundColor: state.showExceptions
                ? '#f4f4f4'
                : 'transparent',
              cursor: state.showExceptions ? 'pointer' : undefined,
              fontWeight: state.showExceptions ? 'normal' : 'bold',
              padding: '4px 1px',
            }}
            onClick={() => {
              if (state.showExceptions) {
                onChangeShowException(false);
                setState({ showExceptions: false });
              }
            }}
          >
            {t('programacoes')}
          </span>
          <span
            style={{
              borderBottom: '1px solid lightgrey',
            }}
          />
          <span
            style={{
              borderLeft: '1px solid lightgrey',
              borderRight: '1px solid lightgrey',
              textAlign: 'center',
              fontSize: '90%',
              borderBottom: state.showExceptions
                ? 'none'
                : '1px solid lightgrey',
              backgroundColor: state.showExceptions
                ? 'transparent'
                : '#f4f4f4',
              cursor: !state.showExceptions ? 'pointer' : undefined,
              fontWeight: !state.showExceptions ? 'normal' : 'bold',
              padding: '4px 1px',
            }}
            onClick={() => {
              if (!state.showExceptions) {
                onChangeShowException(true);
                setState({ showExceptions: true });
              }
            }}
          >
            {t('excecoes')}
          </span>
          <span
            style={{
              borderBottom: '1px solid lightgrey',
            }}
          />
        </div>
      </Flex>
      <div
        style={{
          padding: '20px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          ...(size === 'small' && {
            borderLeft: '1px solid lightgrey',
            borderRight: '1px solid lightgrey',
          }),
        }}
      >
        <span style={{ fontSize: '18px' }}>
          {`${t('total')}: ${state.showExceptions ? exceptions.length : schedules.length}`}
        </span>
        {(!hideAddButton && verifyProfileCanManageProgramming()) && (
          <LimitExceedTooltip disabled={limitExceeded} isException={state.showExceptions}>
            <Button
              variant={limitExceeded ? 'disabled' : 'primary'}
              style={{
                width: 'fit-content',
                padding: '6px 15px',
                ...(!limitExceeded && { backgroundColor: '#363BC4' }),
              }}
              onClick={() => onAddEdit()}
              disabled={limitExceeded}
            >
              {t('botaoAdicionarProgramacaoExcecao', {
                value: state.showExceptions
                  ? t('excecao')
                  : t('Programacao'),
              })}
            </Button>
          </LimitExceedTooltip>
        )}
      </div>
      <div
        style={{
          padding: '20px',
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          ...(size === 'small' && {
            height: '677px',
            overflowX: 'hidden',
            overflowY: 'scroll',
            justifyContent: 'start',
            borderLeft: '1px solid lightgrey',
            borderRight: '1px solid lightgrey',
            gap: state.showExceptions ? 0 : 10,
            width: '100%',
            flexDirection: state.showExceptions ? 'column' : 'row',
          }),
        }}
      >
        {!state.showExceptions && schedules.map((sched, index) => (
          <SchedCard
            key={sched.SCHED_ID}
            driCfg={driCfg}
            sched={sched}
            onEdit={() => onAddEdit(sched)}
            onDelete={() => onDelete(sched)}
            size={size}
            canManageProgramming={verifyProfileCanManageProgramming()}
          />
        ))}
        {state.showExceptions && exceptions.length > 0 && (
          <>
            <Flex
              style={{
                marginLeft: '43px',
              }}
              flexDirection="row"
            >
              <div
                style={{
                  fontWeight: 'bold',
                  width: '42px',
                  fontSize: '13px',
                }}
              >
                {t('titulo')}
              </div>
              <div
                style={{
                  fontWeight: 'bold',
                  marginLeft: '193px',
                  width: '42px',
                  fontSize: '13px',
                }}
              >
                {t('Data')}
              </div>
              <div
                style={{
                  fontWeight: 'bold',
                  marginLeft: '61px',
                  width: '111px',
                  fontSize: '13px',
                }}
              >
                {t('repetirTodoAno')}
              </div>
              <div
                style={{
                  fontWeight: 'bold',
                  marginLeft: '28px',
                  width: '42px',
                  fontSize: '13px',
                }}
              >
                {t('inicio')}
              </div>
              <div
                style={{
                  fontWeight: 'bold',
                  marginLeft: '33px',
                  width: '30px',
                  fontSize: '13px',
                }}
              >
                {t('fim')}
              </div>
            </Flex>
            {exceptions.map((exception) => (
              <Flex
                style={{
                  marginTop: '5px',
                  marginLeft: '16px',
                }}
                flexDirection="column"
                key={exception.SCHED_ID}
              >
                <ExceptionSchedCard
                  sched={exception}
                  onEdit={() => onAddEdit(exception)}
                  onDelete={() => onDelete(exception)}
                  size={size}
                />
              </Flex>
            ))}
          </>
        )}
      </div>
    </Flex>
  );
};
